import '../css/CardProfile.css'
import profile from '../assets/profile.jpg'
import cardStyle from '../css/CardProfile.module.css'
import bg from '../assets/bg.jpg'
import React, { useState } from "react";

const CardProfile = () => {

    const [active, setActive] = useState(false);

    const openWa = () => {
        setActive(!active);
        setTimeout(() => {
            window.open("https://bit.ly/chat_via_wa_here", "_blank");
            setActive(active)
        }, 100);
    };

    return (
        <div className='card-container'>
            <div className='card'>
                <img className='img-profile' src={profile} />
                <div className='title'>Random Developer</div>
                <div className='name'>Muhammad Yashlan Iskandar</div>
                <div className='desc'>Saya sedang menunggu tiket gratis liburan ke planet mars</div>
                <button
                    className={active ? 'button-contact-pressed' : 'button-contact'}
                    onClick={openWa}>
                    Contact Me
                </button>
            </div>

            {/* <div className='card'>
                <img className='img' src={profile} />
                <div className= 'title'>Frontend Developer</div>
                <div className= 'name'>Muhammad Yashlan Iskandar</div>
                <div className= 'desc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</div>
                <button className= {cardStyle.button}>Contact Me</button>
            </div> */}

        </div>
    )
}

export default CardProfile;